import { Component, OnDestroy, inject } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { UnrecoverableStateEvent, VersionEvent } from "@angular/service-worker";
import { environment } from "@e-tenant-hub/environments";
import { PwaService } from "@e-tenant-hub/shared/common";
import { CookiesAcceptance } from "@e-tenant-hub/shared/rentals";
import {
	CacheKeys,
	CacheStorageService,
	googleAnalytics,
	googleAnalyticsHeadScripts,
	updateVersionEvent,
} from "@e-tenant-hub/shared/utils";
import { CookieService } from "ngx-cookie-service";
import { Subject, takeUntil } from "rxjs";
@Component({
	selector: "cp-root",
	templateUrl: "./app.component.html",
})
export class AppComponent implements OnDestroy {
	private readonly _pwaService = inject(PwaService);
	private readonly cacheService = inject(CacheStorageService);
	private readonly cookieService = inject(CookieService);
	private readonly router = inject(Router);

	private destroy$: Subject<void> = new Subject<void>();

	constructor() {
		this.enableGoogleAnalytics();
		googleAnalyticsHeadScripts(environment.googleAnalyticsCode);
		// When a new version is detected
		this._pwaService.onNewVersionReady$.pipe(takeUntil(this.destroy$)).subscribe((event: VersionEvent) => {
			if (event.type === "VERSION_READY") {
				updateVersionEvent(event.currentVersion.hash, event.latestVersion.hash);
			}

			document.location.reload();
		});

		// When the service worker is not able to serve assets
		this._pwaService.onUnrecoverableVersion$
			.pipe(takeUntil(this.destroy$))
			.subscribe((event: UnrecoverableStateEvent) => {
				console.log(`An error occurred that we cannot recover from: ${event.reason}`);
			});

		// Register a check at the launch of the app and also every six hours
		this._pwaService.registerCheckUpdates();

		// Add Google Analytics for each page of the project
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				const url = event["url"];

				if (url !== null && url !== undefined && url !== "" && url.indexOf("null") < 0) {
					googleAnalytics(url, environment.appName, environment.appVersion, environment.googleAnalyticsCode);
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private enableGoogleAnalytics(): void {
		const cookieAcceptance = this.cacheService.retrieveCacheValue<CookiesAcceptance>(
			CacheKeys.APPROVAL_COOKIES_INFO
		);
		if (!cookieAcceptance) return;
		if (cookieAcceptance.choice === "REFUSED" || !cookieAcceptance.acceptedGA) {
			if (environment.googleAnalyticsCode) {
				const disableGa = "ga-disable-" + environment.googleAnalyticsCode;
				//Check if cookie does not exist
				if (!this.cookieService.check(disableGa)) {
					const dateNow = new Date();
					const expirationDate = new Date(new Date().setMonth(dateNow.getMonth() + 6));
					this.cookieService.set(disableGa, "true", expirationDate.getTime());
				}
			}
		}
	}
}
