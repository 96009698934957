import { inject, Injectable, OnDestroy } from "@angular/core";
import { AuthService } from "@e-tenant-hub/shared/auth";
import { APP_CONFIG } from "@e-tenant-hub/shared/config";
import { RentalsHttpClient } from "@e-tenant-hub/shared/rentals";
import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
	IHttpConnectionOptions,
	LogLevel,
} from "@microsoft/signalr";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { SignalRSubscribeConstant } from "../constants";
import { Logger } from "./logger.service";

const log = new Logger("SignalRService");

@Injectable({
	providedIn: "root",
})
export class SignalRService implements OnDestroy {
	private readonly appConfig = inject(APP_CONFIG);
	private readonly authService = inject(AuthService);
	private readonly httpClient = inject(RentalsHttpClient);

	private connection: HubConnection;

	private onAuthorizationChangeSubscription?: Subscription;
	private onHubGroupRegistrationSubscription?: Subscription;
	private onHubGroupRemoveRegistrationSubscription?: Subscription;

	private onNotifyBankCardTransactionUpdatedSubject = new BehaviorSubject<string>("");
	notifyBankCardTransactionUpdated$: Observable<string> =
		this.onNotifyBankCardTransactionUpdatedSubject.asObservable();

	private lastUserId?: string;

	constructor() {
		const httpTransportType = {
			withCredentials: false,
			accessTokenFactory: () => this.authService.accessToken,
		} as IHttpConnectionOptions;

		this.connection = new HubConnectionBuilder()
			.withUrl(this.appConfig.hostHub, httpTransportType)
			.configureLogging(LogLevel.Warning)
			.build();

		this.subscribeToAuthorizationChange();
		this.subscribeToReceivedEvents();
		log.info("Start SignalR connection");
	}

	ngOnDestroy(): void {
		this.onAuthorizationChangeSubscription?.unsubscribe();
		this.onHubGroupRegistrationSubscription?.unsubscribe();
		this.onHubGroupRemoveRegistrationSubscription?.unsubscribe();
	}

	runRegistration(currentUserId: string): void {
		// first connection or new connection --> disconnected and a current  user id
		if (this.connection.state === HubConnectionState.Disconnected && currentUserId) {
			this.initConnection(currentUserId);
			return;
		}

		if (!this.connection.connectionId) {
			return;
		}

		// User disconnect and connect with other account
		if (this.connection.state !== HubConnectionState.Disconnected && currentUserId && !this.lastUserId) {
			this.ensureHubConnected();
			this.registerUserToGroupHub(this.connection.connectionId, currentUserId);
			return;
		}
	}

	closeConnection(userId: string): void {
		if (this.connection.state === HubConnectionState.Disconnected || !this.connection.connectionId) return;
		this.unregisterUserToGroupHub(this.connection.connectionId, userId);
		return;
	}

	private subscribeToAuthorizationChange(): void {
		this.onAuthorizationChangeSubscription?.unsubscribe();
		this.onAuthorizationChangeSubscription = this.authService.authorizationResult$.subscribe(
			(authorizationResult) => {
				if (!authorizationResult.user || !authorizationResult.isAuthenticated) return;
				this.runRegistration(authorizationResult?.user?.id);
			}
		);
	}

	// Subscribe to all event from the hub
	private subscribeToReceivedEvents(): void {
		this.connection.on(SignalRSubscribeConstant.notifyBankCardTransactionUpdated, (state: string) => {
			this.onNotifyBankCardTransactionUpdatedSubject.next(state);
		});
	}

	private initConnection(currentUserId: string): void {
		this.connection
			.start()
			.then(() => {
				this.ensureHubConnected();
				if (!this.connection.connectionId) return;
				this.registerUserToGroupHub(this.connection.connectionId, currentUserId);
			})
			.catch((reason) => {
				log.error(reason);
			});
	}

	private registerUserToGroupHub(connectionId: string, currentUserId: string): void {
		this.lastUserId = currentUserId;

		this.onHubGroupRegistrationSubscription?.unsubscribe();
		this.onHubGroupRegistrationSubscription = this.httpClient
			.post<boolean>(`/api/signalr/group`, { connectionId, userId: currentUserId })
			.subscribe();

		log.info(`SignalR add registration to group: ${currentUserId}`);
	}

	private unregisterUserToGroupHub(connectionId: string, userId: string): void {
		this.lastUserId = undefined;

		this.onHubGroupRemoveRegistrationSubscription?.unsubscribe();
		this.onHubGroupRemoveRegistrationSubscription = this.httpClient
			.delete<boolean>(`/api/signalr/group`, {
				body: { connectionId, userId },
			})
			.subscribe();
		log.debug(`SignalR remove registration to group: ${userId}`);
	}

	private ensureHubConnected(): void {
		if (!this.connection.connectionId) throw new Error("SignalR is not connected yet.");
	}
}
