import { inject, Injectable, NgZone } from "@angular/core";
import { fromEvent, merge, Subject, switchMap, takeUntil, tap, timer } from "rxjs";
import { Logger } from "./logger.service";

const log = new Logger("InactivityService");

const INACTIVITY_TIMEOUT = 1 * 60 * 1000; // 1 minute en millisecondes

@Injectable({
	providedIn: "root",
})
export class InactivityService {
	private ngZone = inject(NgZone);

	private stopTimer$ = new Subject<void>();
	private startTimer$ = new Subject<void>();
	private activityEvents$ = merge(
		fromEvent(document, "mousemove"),
		fromEvent(document, "mousedown"),
		fromEvent(document, "touchstart"),
		fromEvent(document, "touchmove"),
		fromEvent(document, "scroll"),
		fromEvent(document, "keydown"),
		this.startTimer$
	);

	private inactivitySubject$ = new Subject<boolean>();
	onInactivity = this.inactivitySubject$.asObservable();

	startTimer(): void {
		this.ngZone.runOutsideAngular(() => {
			this.resetTimer(); // Reset before start
			this.activityEvents$
				.pipe(
					switchMap(() => timer(INACTIVITY_TIMEOUT)),
					tap(() => this.inactivitySubject$.next(true)),
					takeUntil(this.stopTimer$) // Stop the timer
				)
				.subscribe();

			this.startTimer$.next();
		});
	}

	stopTimer(): void {
		this.stopTimer$.next(); // Stop timer
		this.stopTimer$.complete(); // Complete to avoid new emits
		this.stopTimer$ = new Subject<void>(); // Reset for the next new timer
	}

	private resetTimer(): void {
		this.stopTimer(); // Stop the old timer
	}
}
