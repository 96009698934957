import { ComponentType } from "@angular/cdk/portal";
import { Injectable, inject } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ResponsiveModalContainerService } from "@e-tenant-hub/shared/ui/responsive/modal-container";
import { Observable, of } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class WebModalContainerService<T> extends ResponsiveModalContainerService<T> {
	private readonly dialog = inject(MatDialog);

	private dialogRef?: MatDialogRef<T>;
	private data: unknown;

	constructor() {
		super();
	}

	override openDialog(component: ComponentType<T>, data?: unknown, config?: any): MatDialogRef<T, any> | undefined {
		this.dialogQueue.push({ componentType: component, data: data, config: config });
		return this.openNextDialog();
	}

	private openNextDialog(): MatDialogRef<T, any> | undefined {
		if (this.dialogRef) return;

		const modalEntity = this.dialogQueue.shift();

		if (!modalEntity || !modalEntity.componentType) return;

		this.setDialogData(modalEntity.data);

		this.dialogRef = this.dialog.open(modalEntity.componentType, {
			width: modalEntity.config?.width ?? undefined,
			minWidth: "33vw",
			maxHeight: "85vh",
			maxWidth: modalEntity.config?.maxWidth ?? undefined,
			disableClose: true,
			data: modalEntity.data,
			autoFocus: false,
		});

		return this.dialogRef;
	}

	override closeDialog(dialogResult?: string): void {
		this.dialogRef?.close(dialogResult);
		this.dialogRef = undefined;
		this.data = undefined;
		setTimeout(() => this.openNextDialog(), 1000);
	}

	override getDialogData(): unknown {
		return this.data;
	}

	override reloadData(data: unknown): void {
		this.setDialogData(data);
	}

	override afterClosed(): Observable<any> {
		if (!this.dialogRef) return of();

		return this.dialogRef.afterClosed();
	}

	private setDialogData(data: unknown) {
		this.data = data;
	}
}
